import cs from 'classnames';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Tooltip } from 'antd';
import './Select.scss';

const getOptionClasses = (optionProps) => cs('r-select-option', {
  'r-select-option__danger': optionProps.danger,
});

const Option = ({ children, ...props }) => {
  const option = props.data;
  return (
    <components.Option {...props}>
      <div className={getOptionClasses(option)}>
        {
          option.icon ? (
            <div className="r-select-option-icon">
              { option.icon }
            </div>
          ) : null
        }
        { children }
      </div>
    </components.Option>
  );
};

const MultiValueLabel = ({ children, ...props }) => (
  <components.MultiValueLabel {...props}>
    {
      children?.length >= 25 ? (
        <Tooltip title={children}>
          { `${children.slice(0, 25)}...` }
        </Tooltip>
      ) : children
    }
  </components.MultiValueLabel>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {
      children?.length >= 25 ? (
        <Tooltip title={children}>
          { `${children.slice(0, 25)}...` }
        </Tooltip>
      ) : children
    }
  </components.SingleValue>
);

export default ({
  label,
  className,
  required,
  error,
  creatable,
  value,
  multiple,
  options,
  onChange,
  onAdd,
  onDelete,
  onMenuOpen,
  onMenuClose,
  onAsyncSearch,
  onLoadNextPage,
  asyncSearch,
  // icon,
  blueHighlight = false,
  redHighlight = false,
  usePortal = false,
  searchable = false,
  hashSet = false,
  disableClear = false,
  loading = false,
  disabled = false,
  placeholder = 'Select',
  creatablePlaceholder = 'Create',
}) => {
  const Heading = (
    label ? (
      <span className="r-select-label">
        { required ? <span className="r-select-asterisk">*</span> : null }
        { label }
      </span>
    ) : null
  );

  const Footer = (
    error ? (
      <span className="r-select-error">{ error }</span>
    ) : null
  );

  const onOptionChange = (e, modification) => {
    const {
      action,
      option,
      removedValue,
    } = modification;

    if (onAdd) {
      if (action === 'create-option' || action === 'select-option') {
        onAdd(option);
      }
    }

    if (onDelete) {
      if (action === 'remove-value' || action === 'pop-value') {
        if (removedValue) {
          onDelete(removedValue);
        }
      }
    }

    if (onChange) {
      onChange(e);
    }
  };

  const Props = {
    value,
    placeholder,
    options,
    onChange: onOptionChange,
    ...onLoadNextPage && {
      onMenuScrollToBottom: onLoadNextPage,
    },
    ...onMenuOpen && {
      onMenuOpen,
    },
    ...onMenuClose && {
      onMenuClose,
    },
    ...asyncSearch && {
      filterOption: () => true,
      onInputChange: onAsyncSearch,
    },
    className: 'r-select-container',
    isClearable: !disableClear,
    isMulti: multiple,
    isSearchable: searchable,
    isDisabled: disabled,
    isLoading: loading,
    components: {
      Option,
      MultiValueLabel,
      SingleValue,
    },
    ...blueHighlight && {
      className: 'r-select-blue-highlight',
    },
    ...redHighlight && {
      className: 'r-select-red-highlight',
    },
    styles: {
      control: base => ({
        ...base,
        borderColor: '#e1e4e8',
      }),
      placeholder: base => ({
        ...base,
        color: '#b0b7c3',
      }),
      menuPortal: base => ({
        ...base,
        zIndex: 9999,
        fontSize: 14,
      }),
      multiValue: (styles) => ({
        ...styles,
        maxWidth: 200,
      }),
    },
  };

  const HashSetProps = {
    noOptionsMessage: () => null,
    components: {
      Menu: () => null,
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      MultiValueLabel,
    },
  };

  const SelectClasses = cs('r-select', {
    'r-select__disabled': disabled,
    [className]: !!className,
  });

  if (creatable) {
    return (
      <div className={SelectClasses}>
        { Heading }
        <CreatableSelect
          {...Props}
          {...hashSet && HashSetProps}
          formatCreateLabel={userInput => `${creatablePlaceholder} "${userInput}"`}
          {
            ...usePortal && {
              menuPortalTarget: document.body
            }
          }
        />
        { Footer }
      </div>
    );
  }

  return (
    <div className={SelectClasses}>
      { Heading }
      <Select
        {...Props}
        {
          ...usePortal && {
            menuPortalTarget: document.body
          }
        }
      />
      { Footer }
    </div>
  );
};
