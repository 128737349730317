import axios from 'axios';
import { setAuthToken } from './index';

const LIBRARY_URL = `${process.env.REACT_APP_API_ENDPOINT}/library`;

export const GET_LIBRARY_RATE = 'GET_LIBRARY_RATE';
export const GET_LIBRARY_RATE_SUCCESS = 'GET_LIBRARY_RATE_SUCCESS';
export const GET_LIBRARY_RATE_ERROR = 'GET_LIBRARY_RATE_ERROR';

export const ADD_LIBRARY_RATE_ATTACHMENT = 'ADD_LIBRARY_RATE_ATTACHMENT';
export const EDIT_LIBRARY_RATE_ATTACHMENT = 'EDIT_LIBRARY_RATE_ATTACHMENT';
export const DELETE_LIBRARY_RATE_ATTACHMENT = 'DELETE_LIBRARY_RATE_ATTACHMENT';

export const ADD_LIBRARY_RATE = 'ADD_LIBRARY_RATE';
export const EDIT_LIBRARY_RATE = 'EDIT_LIBRARY_RATE';

export const ADD_LIBRARY_RATE_STATUS = 'ADD_LIBRARY_RATE_STATUS';
export const EDIT_LIBRARY_RATE_STATUS = 'EDIT_LIBRARY_RATE_STATUS';
export const CLONE_LIBRARY_RATE_STATUS = 'CLONE_LIBRARY_RATE_STATUS';

export const ADD_LIBRARY_RATE_CUSTOM_FIELD = 'ADD_LIBRARY_RATE_CUSTOM_FIELD';
export const EDIT_LIBRARY_RATE_CUSTOM_FIELD = 'EDIT_LIBRARY_RATE_CUSTOM_FIELD';
export const DELETE_LIBRARY_RATE_CUSTOM_FIELD = 'DELETE_LIBRARY_RATE_CUSTOM_FIELD';

export const getLibraryRate = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIBRARY_RATE });
      const res = await axios.get(`${LIBRARY_URL}/library-rates/${id}`);
      dispatch({
        type: GET_LIBRARY_RATE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_LIBRARY_RATE_ERROR,
      });
      throw response.data;
    }
  };
};

export const getLibraryRateMap = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-rates/${id}/map`, {
        params: { archived: false }
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryRateAttachment = ({ rateId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rates/${rateId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_LIBRARY_RATE_ATTACHMENT,
        payload: {
          rateId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateAttachment = ({
  rateId,
  attachmentId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/${rateId}/attachments/${attachmentId}`, data);
      dispatch({
        type: EDIT_LIBRARY_RATE_ATTACHMENT,
        payload: {
          rateId,
          attachmentId,
          data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRate = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateAttachment = ({ rateId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/${rateId}/attachments`, attachment);
      dispatch({
        type: ADD_LIBRARY_RATE_ATTACHMENT,
        payload: {
          rateId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRate = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/${id}`, data);
      dispatch({
        type: EDIT_LIBRARY_RATE,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatus = (id, statusId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/${id}/statuses/${statusId}`, data);
      dispatch({
        type: EDIT_LIBRARY_RATE_STATUS,
        payload: {
          rateId: id,
          rateStatusId: statusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const cloneLibraryRateStatus = ({ rateId, statusId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/${rateId}/statuses/${statusId}/clone`);
      dispatch({
        type: CLONE_LIBRARY_RATE_STATUS,
        payload: {
          rateId,
          statusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateStatus = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/${id}/statuses`, data);
      dispatch({
        type: ADD_LIBRARY_RATE_STATUS,
        payload: {
          rateId: id,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateStatusCustomField = ({
  rateId,
  rateStatusId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/${rateId}/statuses/${rateStatusId}/custom-fields`, data);
      dispatch({
        type: ADD_LIBRARY_RATE_CUSTOM_FIELD,
        payload: {
          rateId,
          rateStatusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatusCustomField = ({
  rateId,
  rateStatusId,
  customFieldRecordId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/${rateId}/statuses/${rateStatusId}/custom-fields/${customFieldRecordId}`, data);
      dispatch({
        type: EDIT_LIBRARY_RATE_CUSTOM_FIELD,
        payload: {
          rateId,
          rateStatusId,
          customFieldRecordId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryRateStatusCustomField = ({
  rateId,
  rateStatusId,
  customFieldRecordId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rates/${rateId}/statuses/${rateStatusId}/custom-fields/${customFieldRecordId}`);
      dispatch({
        type: DELETE_LIBRARY_RATE_CUSTOM_FIELD,
        payload: {
          rateId,
          rateStatusId,
          customFieldRecordId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};
