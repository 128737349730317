import moment from 'moment';
import { PROVINCE_LIST, renderStateName, STATE_LIST } from '../../services/StateService';
import { INPUT_TYPE } from '../../enums';
import ProjectsTypeAhead from '../../components/ProjectsTypeAhead';

const processOpenObjections = (
  objections,
) => (objections.reduce((acc, num) => ([
  ...acc,
  {
    filingId: num.id,
    deadline: num.objection && num.objection.respondByDate,
    letterDate: num.objection && num.objection.letterDate,
    filingType: num.filingType,
    objectionId: num.objection && num.objection.id,
    objectionsCount: (num.objection && num.objection.objectionsCount) || 1,
    details: {
      stateCode: num.state,
      state: renderStateName(num.state),
      trackingNumber: num.customTrackingNumber || num.trackingNumber,
      project: num.project,
      product: num.product,
    },
    isHidden: (num.meta && num.meta.objections
      && num.objection && num.objection.id && num.meta.objections[num.objection.id]
      && num.meta.objections[num.objection.id].isHidden) || false,
    assignedResponders: (num.meta && num.meta.objections
      && num.meta.objections[num.objection.id]
      && num.meta.objections[num.objection.id].responders) || {},
  },
]), []));

const processClosedObjections = (objections) => (objections.reduce((acc, num) => ([
  ...acc,
  {
    filingId: num.id,
    deadline: num.objection && num.objection.respondByDate,
    letterDate: num.objection && moment.utc(num.objection.letterDate).format('LL'),
    filingType: num.filingType,
    objectionId: num.objection && num.objection.id,
    objectionsCount: (num.objection && num.objection.objectionsCount) || 1,
    details: {
      stateCode: num.state,
      state: renderStateName(num.state),
      trackingNumber: num.customTrackingNumber || num.trackingNumber,
      project: num.project,
      product: num.product,
    },
  },
]), []));

const openObjectionsFilters = [
  {
    name: 'Objections',
    field: 'objection.isHidden',
    value: null,
    type: INPUT_TYPE.RADIO,
    tab: 'OPEN_OBJECTION',
    displayValue: (value) => {
      if (value === 'true') {
        return 'Hidden';
      }

      if (value === 'false') {
        return 'Active';
      }

      return '';
    },
  },
  {
    name: 'Assigned To',
    field: 'objection.assignedTo',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'States',
    field: 'states',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Workspace',
    field: 'workspaceIds',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'RAMP Product',
    field: 'productIds',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'RAMP Project',
    field: 'projectIds',
    value: null,
    tab: 'OPEN_OBJECTION',
    type: INPUT_TYPE.DROPDOWN_MULTI,
    component: ProjectsTypeAhead,
    displayValue: (e) => e.map(v => v.label).join(', '),
  },
  {
    name: 'SERFF Product',
    field: 'productNames',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'SERFF Project',
    field: 'projectNames',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'SERFF Project Number',
    field: 'projectNumbers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Authors',
    field: 'authors',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Contacts',
    field: 'contacts',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Reviewers',
    field: 'reviewers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Filing Type',
    field: 'filingTypes',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Type Of Insurance',
    field: 'typeOfInsurances',
    creatable: true,
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Sub-Type Of Insurance',
    field: 'subTypeOfInsurances',
    creatable: true,
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Tracking Number',
    field: 'trackingNumbers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Company Tracking Number',
    field: 'generalInformation.companyTrackingNumbers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Letter Date',
    field: 'objection.letterDates',
    value: null,
    type: INPUT_TYPE.DATE,
    tab: 'OPEN_OBJECTION',
  },
  {
    name: 'Respond By Date',
    field: 'objection.respondByDate',
    value: null,
    type: INPUT_TYPE.DATE,
    tab: 'OPEN_OBJECTION',
  },
];

const closedObjectionsFilters = [
  {
    name: 'Workspace',
    field: 'workspaceIds',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'RAMP Product',
    field: 'productIds',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'RAMP Project',
    field: 'projectIds',
    value: null,
    tab: 'CLOSED_OBJECTION',
    type: INPUT_TYPE.DROPDOWN_MULTI,
    component: ProjectsTypeAhead,
    displayValue: (e) => e.map(v => v.label).join(', '),
  },
  {
    name: 'SERFF Product',
    field: 'productNames',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'SERFF Project',
    field: 'projectNames',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'SERFF Project Number',
    field: 'projectNumbers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Authors',
    field: 'authors',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Contacts',
    field: 'contacts',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Reviewers',
    field: 'reviewers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Filing Type',
    field: 'filingTypes',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Type Of Insurance',
    field: 'typeOfInsurances',
    creatable: true,
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Sub-Type Of Insurance',
    field: 'subTypeOfInsurances',
    creatable: true,
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'States',
    field: 'states',
    value: null,
    type: INPUT_TYPE.DROPDOWN_MULTI,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Tracking Number',
    field: 'trackingNumbers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Company Tracking Number',
    field: 'generalInformation.companyTrackingNumbers',
    value: null,
    type: INPUT_TYPE.HASH_SET,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Letter Date',
    field: 'objection.letterDates',
    value: null,
    type: INPUT_TYPE.DATE,
    tab: 'CLOSED_OBJECTION',
  },
  {
    name: 'Respond By Date',
    field: 'objection.respondByDate',
    value: null,
    type: INPUT_TYPE.DATE,
    tab: 'CLOSED_OBJECTION',
  },
];

const getProcessedUsers = (users) => {
  const { active, archived } = users.reduce((acc, user) => {
    if (user.archived) {
      acc.archived.push({
        label: `${user.firstName} ${user.lastName} (Archived)`,
        value: user._id,
      });
    } else {
      acc.active.push({
        label: `${user.firstName} ${user.lastName}`,
        value: user._id,
      });
    }
    return acc;
  }, {
    active: [],
    archived: [],
  });

  return [
    ...active.sort((a, b) => a.label.localeCompare(b.label)),
    ...archived.sort((a, b) => a.label.localeCompare(b.label)),
  ];
}

export const getFilterValueOptions = ({
  hasCanadianProvincesFeature,
  typeOfInsurancesRules = [],
  subTypeOfInsurancesRules = [],
  filingTypesRules = [],
  products = [],
  workspaces = [],
  users = [],
  entity,
}) => entity.reduce((acc, filter) => {
  if (filter.field === 'states') {
    acc.states = hasCanadianProvincesFeature ? [
      {
        label: 'US States',
        options: STATE_LIST,
      },
      {
        label: 'Canada Provinces',
        options: PROVINCE_LIST,
      },
    ] : STATE_LIST;
  }

  if (filter.field === 'typeOfInsurances') {
    acc.typeOfInsurances = typeOfInsurancesRules.map(typeOfInsurance => ({
      label: typeOfInsurance,
      value: typeOfInsurance,
    }));
  }

  if (filter.field === 'subTypeOfInsurances') {
    acc.subTypeOfInsurances = subTypeOfInsurancesRules.map(subTypeOfInsurance => ({
      label: subTypeOfInsurance,
      value: subTypeOfInsurance,
    }));
  }

  if (filter.field === 'filingTypes') {
    acc.filingTypes = filingTypesRules.map(filingType => ({
      label: filingType,
      value: filingType,
    }));
  }

  if (filter.field === 'productIds') {
    acc.productIds = products.map(product => ({
      label: product.name,
      value: product._id,
    })).sort((a, b) => a.label.localeCompare(b.label));
  }

  if (filter.field === 'workspaceIds') {
    acc.workspaceIds = workspaces.map(workspace => ({
      label: workspace.name,
      value: workspace._id,
    })).sort((a, b) => a.label.localeCompare(b.label));
  }

  if (filter.field === 'objection.assignedTo') {
    acc['objection.assignedTo'] = getProcessedUsers(users);
  }

  if (filter.field === 'objection.isHidden') {
    acc['objection.isHidden'] = [
      {
        name: 'All',
        value: undefined,
      },
      {
        name: 'Hidden',
        value: 'true',
      },
      {
        name: 'Active',
        value: 'false',
      },
    ];
  }

  return acc;
}, {});

// this function is only needed for workspaceIds to productIds mapping
const mergeFilters = (filters) => {
  const mergedData = {};
  const result = [];

  filters.forEach(item => {
    if (item.field === "productIds") {
      if (!mergedData[item.field]) {
        mergedData[item.field] = new Set();
      }
      item.value.forEach(id => mergedData[item.field].add(id));
    } else {
      // Directly add items that are not "productIds"
      result.push(item);
    }
  });

  // Add the merged "productIds" data back to the result
  if (mergedData["productIds"]) {
    result.push({
      field: "productIds",
      value: Array.from(mergedData["productIds"]),
      comparisonOperator: "in"
    });
  }

  return result;
}

const getDateSearchParam = (field, date) => {
  const [start, end] = date.split(' ↔ ');
  return [
    {
      field,
      value: `${start}:00:00:00`,
      comparisonOperator: 'gte',
    },
    {
      field,
      value: `${end}:23:59:59`,
      comparisonOperator: 'lte',
    },
  ];
};

export const getSearchParams = (filters = {}, workspaces = []) => mergeFilters(Object
  .keys(filters)
  .reduce((acc, num) => {
    if (num === 'projectIds') {
      acc.push({
        field: num,
        value: filters[num].map(({ value }) => value),
        comparisonOperator: 'in',
      });
      return acc;
    }

    if ([
      'productIds',
      'objection.assignedTo',
    ].indexOf(num) !== -1) {
      acc.push({
        field: num,
        value: filters[num],
        comparisonOperator: 'in',
      });
      return acc;
    }

    if (num === 'workspaceIds') {
      const workspaceProductIds = filters[num].reduce((productAcc, workspaceId) => {
        const workspace = workspaces.find(w => w._id === workspaceId);

        if (workspace?.products?.length) {
          productAcc.push(...workspace.products.map((product) => product.productId));
        }

        return productAcc;
      }, []);

      if (workspaceProductIds.length) {
        acc.push({
          field: 'productIds',
          value: workspaceProductIds,
          comparisonOperator: 'in',
        });
      }
      return acc;
    }

    if (num === 'objection.isHidden') {
      acc.push({
        field: 'objection.isHidden',
        value: filters[num] === 'true',
        comparisonOperator: 'eq',
      });
      return acc;
    }

    if (num === 'objection.letterDates') {
      acc.push(...getDateSearchParam('objection.letterDates', filters[num]));
      return acc;
    }

    if (num === 'objection.respondByDate') {
      acc.push(...getDateSearchParam('objection.respondByDate', filters[num]));
      return acc;
    }

    if (filters[num]) {
      acc.push({
        field: num,
        value: filters[num],
        comparisonOperator: 'cn',
      });
    }
    return acc;
  }, []));


export {
  processOpenObjections,
  processClosedObjections,
  openObjectionsFilters,
  closedObjectionsFilters,
};
